import { render, staticRenderFns } from "./initiateStocking.vue?vue&type=template&id=866c4080&scoped=true&"
import script from "./initiateStocking.vue?vue&type=script&lang=js&"
export * from "./initiateStocking.vue?vue&type=script&lang=js&"
import style0 from "./initiateStocking.vue?vue&type=style&index=0&id=866c4080&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "866c4080",
  null
  
)

export default component.exports